<template>
  <div class="card card-custom bg-radial-gradient-white gutter-b card-stretch ">
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-primary">{{$t("DASHBOARD.DOCTOR.stats.monthstats")}}</h3>
      <div class="card-toolbar">
        <v-dialog ref="dialog" v-model="modal" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateString"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable type="month">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              {{$t("POPUPS.cancel")}}
            </v-btn>
            <v-btn text color="primary" @click="getMonthStats">
              {{$t("POPUPS.okay")}}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </div>
    <div
      class="card-body d-flex flex-column pr-5 pl-5 pt-5 pb-0"
      style="min-height:320px"
    >
      <div id="chart" v-if="!this.$root.mobile">
        <apexchart
          v-if="isDoneLoading"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="stats"
        ></apexchart>
        <div
          v-if="!isDoneLoading"
          class="text-center mt-5"
          style="width:100%; margin-right:auto; margin-left:auto;"
        >
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </div>

      <div id="chart-week" v-else>
        <template v-if="isDoneLoading">
          <apexchart
            type="bar"
            height="350"
            :options="chartWeekOptions"
            :series="weekStats"
          ></apexchart>

          <!-- pagination -->
          <div class="text-center py-5">
            <v-pagination
              elevation="0"
              v-model="page"
              :length="5"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="setPagination"
            ></v-pagination>
          </div>
        </template>

        <div
          v-if="!isDoneLoading"
          class="text-center mt-5"
          style="width:100%; margin-right:auto; margin-left:auto;"
        >
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "MonthStats",
  //props: ["mfid"],
  //components: {Avatar},
  data() {
    return {
      error: "",
      stats: [],
      weekStats: [],
      page: 1,
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      isDoneLoading: false,
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true
          }
        },
        yaxis: {
          title: {
            text: this.$t("DASHBOARD.DOCTOR.stats.availablity")
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " appointment";
            }
          }
        }
      },
      chartWeekOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: false
          }
        },
        grid: {
      show: false,      // you can either change hear to disable all grids   
    },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true
          }
        },
        yaxis: {
          
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " appointment";
            }
          }
        }
      }
    };
  },
  mounted() {
    this.$moment.locale("en");
    !this.$root.mobile && this.getStatus(this.$moment().format("/YYYY/MM"));
    this.$root.mobile && this.getWeekStatus(this.$moment().format("/YYYY/MM"));
  },
  methods: {
    async getStatus(date) {
      await ApiService.get("custom", "docavail/cal/" + this.$root.docID + date)
        .then(({ data }) => {
          ////console.log(data);
          this.stats = [];
          var avail = [];
          var booked = [];
          var dates = [];
          console.log("data.data", data.data);
          for (var i in data.data) {
            //console.log("item date: ", data.data[i]['date']);
            //console.log("item total available: ", data.data[i]['available']);
            //console.log("item total available: ", data.data[i]['booked']);
            booked.push(data.data[i]["booked"]);
            if (data.data[i]["available"] == -1) {
              avail.push(0);
            } else {
              avail.push(data.data[i]["available"]);
            }
            var d = data.data[i]["date"].split("-");
            dates.push(d[1] + "/" + d[2]);
          }
          //console.log(this.chartOptions.xaxis.categories);
          this.chartOptions.xaxis.categories = dates;
          this.stats.push({ name: this.$t("DASHBOARD.DOCTOR.stats.avail"), data: avail });
          this.stats.push({ name: this.$t("DASHBOARD.DOCTOR.stats.booked"), data: booked });
          console.log("final product", this.stats);
          this.isDoneLoading = true;
          //console.log(this.chartOptions.xaxis.categories);
          this.$moment.locale(localStorage.language);
        })
        .catch(error => {
          this.error = error;
        });
    },

    async getWeekStatus(month, start = 0, end = 7) {
      try {
        this.isDoneLoading = false;
        const { data } = await ApiService.get(
          "custom",
          "docavail/cal/" + JSON.parse(localStorage.userInfo).id + month
        );

        this.weekStats = [];
        var avail = [];
        var booked = [];
        var dates = [];

        !end && (end = data.data.length);

        for (let item of data.data.slice(start, end)) {
          booked.push(item["booked"]);

          if (item["available"] == -1) {
            avail.push(0);
          } else {
            avail.push(item["available"]);
          }
          var d = item["date"].split("-");
          dates.push(d[1] + "/" + d[2]);
        }

        this.chartWeekOptions.xaxis.categories = dates;
        this.weekStats.push({ name: this.$t("DASHBOARD.DOCTOR.stats.avail"), data: avail });
        this.weekStats.push({ name: this.$t("DASHBOARD.DOCTOR.stats.booked"), data: booked });
        console.log(this.weekStats);
        this.isDoneLoading = true;
        this.$moment.locale(localStorage.language);
      } catch (error) {
        this.error = error;
      }
    },
    async getMonthStats() {
      //$refs.dialog.save(this.date);
      console.log("new date is: ", this.date);
      this.modal = false;
      this.isDoneLoading = false;
      this.$moment.locale("en");
      this.getStatus(this.$moment(this.date).format("/YYYY/MM"));
    },

    setPagination() {
      let start, end;
      switch (this.page) {
        case 1:
          start = 0;
          end = 7;
          break;

        case 2:
          start = 7;
          end = 14;
          break;

        case 3:
          start = 14;
          end = 21;
          break;

        case 4:
          start = 21;
          end = 28;
          break;

        case 5:
          start = 28;
          end = null;
          break;

        default:
          break;
      }

      this.getWeekStatus(this.$moment().format("/YYYY/MM"), start, end);
    }
  },
  computed: {
    dateString() {
      this.$moment.locale("en");
      return this.$moment(this.date).format("MMMM");
    }
  }
};
</script>
