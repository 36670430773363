<template>
  <div>
    <v-row>
      <v-col cols="12">
        <BoxedStats />
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <Timeline />
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9">
        <MonthStats ></MonthStats>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import MonthStats from "./comp/monthStats.vue";
import BoxedStats from "./comp/statBoxes.vue";
import Timeline from "./comp/todayApps.vue";

export default {
  name: "DoctorDashboard",
  //props: ["mfid","dialog"],
  components: { MonthStats, BoxedStats, Timeline },
  data() {
    return {
      error: "",
      userInfo: null,
      isProfileComplete: true,
      
    };
  },
  mounted() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
  },
  methods: {
    
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append,
      });
    },
  },
  computed: {},
};
</script>
